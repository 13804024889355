export const allowlistAddresses = [

  ["0x19ac39bc37ef8a9a019b2d244f69e5ab0c97afb0",3],
  ["0xdc48dcda07955a9c1a53c2e0d4163286764c4cbc",3],
  ["0x5243bde21b1bfa9bae695b3dd1e6346aed8d4a30",3],
  ["0x30b425d3a7bbd7b33d11be9af496af5908ebde1d",3],
  ["0x49ef397534f6299e0a8fee75bc16f09726e11ef2",3],
  ["0x5d61f268eef978c27d56fc2722111481e6ae21ef",3],
  ["0xfe8c928589fcd62fb23820eddb3a03be34ac206e",3],
  ["0xdcc4b9b0bf2bdeaeed3a48ee1e2877aaaf337a70",3],
  ["0xca689c3234de16b8f5c5a7f94e740ae902ec7e6f",3],
  ["0x5ba35d07feca20a0d38d25ac6f33a729e23c7276",3],
  ["0x572019828c0cff57ae9adb1924e06d40a798aec5",3],
  ["0x52338c014cf9e533a55954ca4b18e30edc2f8568",3],
  ["0x3135dad86c3390b38ecd7cd00af55e97122b479f",3],
  ["0x0a11605280c54f62f4968dbd7078981006716355",3],
  ["0x109746975275f5d9d99c4166e13d167153687e0d",3],
  ["0x612ca623e264782e3eae3e6efd589c2ff5a2b51c",3],
  ["0xe6e44c3830b9438b54a64a98d8f284d8412e5c13",3],
  ["0x7a23883b26973eb32ae3ca6ccb29513421649a5e",3],
  ["0x8ebaf3f8481a00c8a98e30113b4f3b0cfc94d606",3],
  ["0xed9b2d684c3144caa12a67c30700a5e7e8a889c9",3],
  ["0xed937a015c832b3c067e28c68fd980100175e6e9",3],
  ["0x8c529a77713348e7ef0eeffefbe42dcec8f90296",3],
  ["0x8188c792f4fcdcdbc0a673a0f4c8d6a667a8d661",3],
  ["0x0f5a0b182eab456a773b2f518eff49c7237613e4",3],
  ["0x9cc1d4af4bd2f9123e66433313be82afa802393f",3],
  ["0x45ccc59ddf78de3252379b9189d36728e55fa644",3],
  ["0x24d4302ef5fcf3569f843960ee02de2f168a1f53",3],
  ["0x07485997825cffd08e9f0e490fb41f3bb4d0280e",3],
  ["0xffbb629202ccffb9d6284400bc4f6e0c3ae48d18",3],
  ["0x858721c0b598a1c54bf5ba063520154aea2f73e6",3],
  ["0x75c4d7544dc881596e3679345a7e915f62171dea",3],
  ["0x6e269cfdb481d1804c27fb8eb14110784e8a2132",3],
  ["0xca30ba08dfecb451ce64bc765df1749185106bbf",3],
  ["0x08566941ed0410dfc5cdf038a4d3ecf813c69378",3],
  ["0xb224fac3456408ead8cb9add7ef1878887fcdccd",3],
  ["0x895f25a04c7c4f0ce96bde5a83dbfba13982c09c",3],
  ["0x86eb8be678274ba4c47af17fa8511c3f9c631bbc",3],
  ["0x3f3b1a82bdf41e7fbaf7ab1d4da42d1b235b14e6",3],
  ["0x972f49020130be2fee1ba7b2db2b2b571f131765",3],
  ["0x2d8dd5b7fb0ca098f0a230b41e3c5cada6f1f165",3],
  ["0xd2f18ae6dd74ecac828bef77f95cbf42d0d3bd6e",3],
  ["0x26384f0bcfa3e4fc7e37ec920102da2cd7a13bb8",3],
  ["0xb237d44b7c9f5c0b175cb7d205b97a19e958c514",3],
  ["0x3856707cda19709e8c76f9b768279ec11f7119e7",3],
  ["0xd38edf9d64ac62ed5bd6cf42a62cea3aadd6196b",3],
  ["0x9b1aa52daffbcb09312bd00fc20fec3d150befd7",3],
  ["0x37d4f083af7fbb633dc804f59053f57b410bf962",3],
  ["0xf929ac599c54ab2320fbefcbbefba27dfe558607",3],
  ["0x138c9b05cf95003e515744ed77015c489fdc18c5",3],
  ["0x119a468dea5fa18e676587a6cf8e0cb4898cbab9",3],
  ["0x1a8e50f629255c5a710b69e7c30fc45a07e8368c",3],
  ["0x3c8d1a725fbe1ccade8308bcdab432351a608656",3],
  ["0x603e347d273fdf9e99d89c310524ed7e075f669d",3],
  ["0xfecfefc100ae961359e23b9d40b6a7f5f9931f4b",3],
  ["0x163ad79dc9a292739291a1a90ffbd4c89b08be2d",3],
  ["0xd99dae2407acecbecdf107a39dee33d2d27f5b07",3],
  ["0xfee7ed85a6dec12cfcc83367c91def6231476ac2",3],
  ["0x9e9491495ae9dd1ee6b407291464e2c80094dbd6",3],
  ["0x7d259b58079f26333baef34fa09e919b6a0d3656",3],
  ["0xb25469ddc31a5fb881454db41529bdfe186e3e23",3],
  ["0xdc20d877264665c9cf47cf7e12fc163dce69ef05",3],
  ["0x3d082cc57f2d7b6ff613d570a7597e085b1d923b",3],
  ["0xe25fe4213821705c3b7b5251089e57cc889f0af4",3],
  ["0x8e8b339204a616714f8e7341c0a8b129f8fc5190",3],
  ["0x6d625137183be90842d154a7c306a1f6227c7f5d",3],
  ["0x93cf1eab24e5208934167df5b2fa21be4c4150a1",3],
  ["0x905472fcc4f835f678aa275b35e9e6744da4db0a",3],
  ["0x81837a56eb4635e867e95668fd5f89b0d0e64049",3],
  ["0x6313fdbc70088df6474f00aec0ea38413351e32b",3],
  ["0xa1a45a2b5fcdcdc590f5203d194e563ae6742e32",3],
  ["0x6c69f2af5bc8473ee4f5d281cd8403e45c114553",3],
  ["0x8c3d29212be29dd207a946d18ba33f1ae1b2600e",3],
  ["0x1824a6f56777485fac2ccffb8d4417dbf1198884",3],
  ["0xb000e159846be4ffc371ff633fdfe2bc3dbcbba4",3],
  ["0x4d9afe885d24a31ebd505d44736b9dc74ef519ad",3],
  ["0x89255b3f71ad8a5a044448935d8433b17cb195a3",3],
  ["0x87481851f2cacfff8763f49bff57e7afe3ab87dc",3],
  ["0xc39da4d109b30b7f0a3e1d383acb528488a3fe8a",3],
  ["0x44e5ba0aece3cb5337c223e8493e6c23d46e12c3",3],
  ["0x6d0b7e7e27f023b9f281291386c2e307cd366042",3],
  ["0x4035b7a01244c6900e37f8b0950304401be5ee2b",3],
  ["0x2d2be7a53597c1564d97f42d0bbbe57e305eef6b",3],
  ["0x6e08ec7dd86c655b0ab3cf909051aa01465661fb",3],
  ["0xf00477dd916076883c1b984684e795bd7ab26141",3],
  ["0xd0d5250a553d07f6622b19b04929f64e7876db93",3],
  ["0x329bb6b4660488fa3aa84e392ab78705b9d9b880",3],
  ["0x45a5b832f2fb07a77636ef5456cfa54f109bfb41",3],
  ["0x2c7cd75000407adfe2b33a3f717d85b90219f4f3",3],
  ["0x3b26d7d309cbe605b7caab1f59780d4d9614631a",3],
  ["0x60f5a4c1d11b873191447057f2d78f929643d2bb",3],
  ["0x56b2cd5133af50e9200116d28af92b340994e979",3],
  ["0xf68796f75b862f3d3b90763ef09ef0f733519493",3],
  ["0xea33723813f0a6b5179a8ca4a2d5af603d41fb3b",3],
  ["0x19763217c16ddfe2edaec7f90eb8983a73549b1f",3],
  ["0xa91b4d9e136035a10cf18e16b43bb0b589010788",3],
  ["0xfc9c44803c1f5ecd6a47e090523cce822a0cc350",3],
  ["0xe5fea09f8d0506ec709767b91533f736d63fb735",3],
  ["0xeffa0b6b2ed1a91c14c281f23eabc4e2d7ff6fcf",3],
  ["0x11e0185434b499cac2cf77e0c3a96d6fc9578c14",3],
  ["0xda6c3b1ad506adb827406669dbbda030154c4beb",3],
  ["0x0b7fd6532ffcf343e230c15640dfd847139533ee",3],
  ["0x8a18da56ba8aee69e03b81ed655b9adcbd288ecf",3],
  ["0xf8b02593e168139873f1c9653a5d08519b06bca0",3],
  ["0x66aa50d9b057d7946c8a4fa6986598534f3806c8",3],
  ["0xf2466d553b1d0cdf6ede3e8c2d3652e7338d3c70",3],
  ["0x2eee5f7f156d606306773fcf98e7d56bdb669235",3],
  ["0x3428bacd15d15e5c9b8bb2f9e1445f49acc8ad17",3],
  ["0x16c0be20bb68a5930c7176f2f42ca82346b3246f",3],
  ["0xc8b6e182de32717071cc466837f0b88013e1fde9",3],
  ["0x18f1fb47fa1c983e9c7e6548cbf550243d880e41",3],
  ["0xa03b5ab3e4b6ac9a20e3099739d7dfbcca451685",3],
  ["0x80e3e67a02aba5907bf51ea017d36fa60abfdaf5",3],
  ["0xbee01f8b6dbabef5a5b55c07425f0b9b72469f1f",3],
  ["0x03aa122abab15aa852b1450596dbacd2345317bd",3],
  ["0xd99694fd205cc2c9d5ebffc5fd5ca5cb5416ed03",3],
  ["0x5b87d81f1c6e980b13dd7297fe5a77388568d295",3],
  ["0xca46546f760d2b27f43daefe733fa53a32054478",3],
  ["0x49025f0e9d0d1cb1e600f4036caf562af6dff44c",3],
  ["0xdc391c873d890519c58a7d151f1e4af951711642",3],
  ["0x2e581428ed71f291a64ac76a03dbe85bad847441",3],
  ["0x9dc5c89dc607035a5c942b24c0783fc56a9f9c50",3],
  ["0xd701bc4145f8314e2d60d24fafd36e6ee385cfec",3],
  ["0x55eae205eae5cda5be9f9cae3fef7dc6e236186d",3],
  ["0x0f986fad391814e50a0c088231c11c510ef63c85",3],
  ["0xabb7943a52b94adddff6be44f56fb163233c827b",3],
  ["0x27c3dcc552a00b08bed774e0f518443d4528880d",3],
  ["0xf092f3f693b0cc613191dd0cf0a3928e700400da",3],
  ["0x869a0d02c9d0ca671909c783a8273aca87a26e79",3],
  ["0x6e81f18d77d35a39c4cd41220b78b2991b349f72",3],
  ["0x094b644f0cba4c8fcc15ba79cc6ecb926989e143",3],
  ["0xd2859644e1b42946ffa35b99880fbcfaa90f4144",3],
  ["0x5c3d97f9a3b7938ca043b3a671b4b05291a94823",3],
  ["0xb812d66fb8715539cd4c1d0ef3bc237f37ed5001",3],
  ["0xd231111d76667f60f8d06689cd63859ffc8afb3e",3],
  ["0xb990378362f9e15b3bfa05206cca8f7145ca703c",3],
  ["0xaa82ae9b1f4278e3c9c33a0e80d556db0f4ca064",3],
  ["0x9245580c7a1c37eb925e93f67ad044145a83b04e",3],
  ["0x2a2f3d9a777038ce331ceeec14472cc6a37ccd62",3],
  ["0x93dca00f77f0bc374ac3a28894649924ffa84c26",3],
  ["0x2ab70125fb07fd5af722efa68d4f8f2f846e8b1e",3],
  ["0x0e9289b0ecb63610cd2fc224c25d642567fc2ba5",3],
  ["0xd1a89c5d59815a1e5c531fa248d21165b0a24a5c",3],
  ["0xc740743cbbffabb69d28b678329977b575afc1dd",3],
  ["0xbf99e9b418b711e94d3c0f55059e48c64603acee",3],
  ["0x529e4dbd63a69eb52f204f8da0835d4b2ad3d826",3],
  ["0xef3414015e61e834b27d0ae177261d98fe11905b",3],
  ["0x492b5864411aca825ab4bb69aaa90cd21e745cb3",3],
  ["0xebd8cb306ac616b0dc592fd316e8943485dec13b",3],
  ["0xa3d2f4e72844ac6088286819aecde4d2e24fad66",3],
  ["0x69a05e766a58f392a384b8d45fa63e1fcc2b13a7",3],
  ["0xd9695ac0efd3b8f11d452e177ba37ea78522f40a",3],
  ["0x51c9631896950a6fd0b03a709614fb75d891b3fa",3],
  ["0xa6d6a49c1ef404c7b59fb6f709201410f712f752",3],
  ["0x6f7164bab40d041abb6e16f0f3913bbac1485ab4",3],
  ["0xf82d62c0e19254f8b66e6130964432cacadf6ce0",3],
  ["0xb5d7cfe079b6a192a072b390fe5d0cf99c5e54d2",3],
  ["0x5e9ec24bb195bef8281c676c8d187daf9dd9f27a",3],
  ["0x9bc4307624534b4409fb802fc0abef160567acca",3],
  ["0x4e02a3818bf2b5fa36809c74270a93131f87e432",3],
  ["0x2b6bcd9bf0df7cf30f10dd193ca6532e0929f682",3],
  ["0x1f5066f3a87c117075f57eb3e9839f3999977fdd",3],
  ["0xe74d4d5b49352439fdf66c4c39248dfffa039cb1",3],
  ["0x020e298a4c6c38d8d03e273f9c9c04ab1b102e17",3],
  ["0x5335c92c8e60aae1e03544e463e3a75abc236b2a",3],
  ["0xf406aed3369614123eb6c8ea6afb12995d49dbdb",3],
  ["0xe7d02aeca52f4000e88c373dc4ef1b022a2884c4",3],
  ["0x0c7d841966696af4d1aff9a56424ab938626a443",3],
  ["0xf1c3ee5405b3eaf7d23881ac4141c1f31f75f933",3],
  ["0x13b209f0444081c713a3d7510abd57a7e2d0c18f",3],
  ["0xdfa6ce5d379a2089c5a65bc8d3bebf3838d4651e",3],
  ["0xe82921cad4f21116e3ab1b09ae0fa161a4135329",3],
  ["0xe730d12649210bf12ea627b192e0248e94edd5b9",3],
  ["0xae62600d0eb545c445ced4c239a4e297f0c20f2e",3],
  ["0x32908a1fc6437fd59ccaedb42df33e0a2e5edeee",3],
  ["0xde5c68ebb8ee5fb4684f16c585a6643c7a2f3c5b",3],
  ["0xfa10abc4f444405784e90b50a7601851f5030f89",3],
  ["0x53a91eff4a415aa7460a55c8611037b436fb1f12",3],
  ["0x523c645cd28a9dd0ea59fc1cf67433e8d840c2c6",3],
  ["0x4e6b569e508e45517773c3e94dabd0f91af2124c",3],
  ["0x0fdf2958b9192cd91dbccc5eb184baaa793e8ec9",3],
  ["0x54105ea638e900f80f3444a1562a92d1a29db1aa",3],
  ["0x882d23171f35e9620ddec45b54f56456f85fc613",3],
  ["0x5c2878b6313cc8b31ffaa470b9d967b12ebfe163",3],
  ["0x21ecffaeb0c1bf526e2df60d014081c1fb512ec3",3],
  ["0xa7b757e57d577e1df7f1dd278303ea74704e6f27",3],
  ["0x96572f0d2d2eac53c23a55e4758f9e4f433cc764",3],
  ["0xf9574d187845f68533651e0bb53c01f70c6f956d",3],
  ["0xfd4059a9d191fe04a5a837b391f300bf67329f31",3],
  ["0xf533c31ff773589fdc4dfa562990459d711f116e",3],
  ["0xae24c5a3728954a04d4d4c87720265521f736b09",3],
  ["0xa2cadff5a9d30cff77bd74b2bfc41cb5ab004fbe",3],
  ["0x02dd0bccf4b407d9c33b7cdc9e69ef0df2f3383a",3],
  ["0xff053e96bd1fd0c65f1aeafa9d56d3e45c9a1d84",3],
  ["0xfe6739ba9eb8dc27ef8b623da42ba2f8a5902d1d",3],
  ["0xc5e97dd71bff9102d3ee9781de07c8cde8391b18",3],
  ["0x00687b375430cb84a70b5c5e1adba641aef5d1f9",3],
  ["0xbeb5ecc2d9f94f9157c7f3744cee058b40e44af6",3],
  ["0xeadc8f8dd75c95c7b92db6d1c7129dd8631b5ea2",3],
  ["0x11a3c12c8e6672f8504c0cbf9f9165093ee97b4e",3],
  ["0xa33b2df047886c5282ba8f7f2b49d2af3c8ee699",3],
  ["0xb7d994657d400d434e84b11d02c935be69957657",3],
  ["0x6cc53e061f2dc93daf05b6905d9433bfa4177cfc",3],
  ["0x370c7e5ba00e78d14c5052f76c47c66f3128af79",3],
  ["0x1f700588da5980005b1ff37f60b965ec5aadd11a",3],
  ["0x6e218ac3a5494aec2f90a5cf6e8f2a31adc8331c",3],
  ["0x2c19a07573c404bfd18a89ec6779029f4c75f628",3],
  ["0x24aae0a135985dc8ae6b8bac64051af645c2b3f9",3],
  ["0x7da008cf87accde185f1324b0338cdf6817e91b0",3],
  ["0x7abaaa0c0a518c803401ab5ed5e176b781989a4e",3],
  ["0xc40c92c6a56d530e2e1ec11f5feaccda5eeb8c07",3],
  ["0x2c4feb03d32fff06522be12bbff4dcd1a5b160eb",3],
  ["0x39b747d4559ae0f79432155feff8637dc6184a7c",3],
  ["0xa89862402d0215703206484c778b77cc1f356408",3],
  ["0xf0c608d3b295ea84741d32bcee79e3c5866c0d4d",3],
  ["0x6fdcb1e36df10a8f7f9707b635c1907ceb8841ba",3],
  ["0xa6cedbd38903fed0f6e103be2e9fa2551703e76b",3],
  ["0x957913b5aafc0fef76d089073de2f87f71bacc75",3],
  ["0x65610ddb7157d4412b84b2d0c39b03986c078f11",3],
  ["0xe93991fc88333da05597c24bcb1ae76a08d886f3",3],
  ["0xae6c486c3aac0d267a31f1b522081feeaa9a9451",3],
  ["0xd1b74e02e51ff3c92c7ba53f893f8c9828071d77",3],
  ["0x729323a98b00aaf252d4a219fb4b67927d61f05a",3],
  ["0x39013a53884593e7d79e05f79ca536a8c2a33369",3],
  ["0x881f6c28bc342cdc8d5e9c665d3e2c1dc9e8998a",3],
  ["0x20c326785e1bd78cf502c22c0913a0f300c8159b",3],
  ["0xe3a562fea2da5b77a46ef9bffd74b72a0073af61",3],
  ["0x68f2be289775c791ca6b2dc4269014eeaeb7caa4",3],
  ["0x6124ebed9bf322d4abc33bf5897abeb95f6644a5",3],
  ["0xf3936c2d5f7286b96b2b6b4854ce6de48918be1b",3],
  ["0x7587db282e23d3d121093cac2390349e743b0efa",3],
  ["0xd6c8422839b7965dade45349f6bcea6b2963b480",3],
  ["0xbf20d937c2d313b25e422528d211ede43b197a5d",3],
  ["0x55fa6481a31f1963d5d6ab16d16e72d7225c3e8b",3],
  ["0xd306c83acf84318a440a40ebeb5ba681d29a7958",3],
  ["0xb8c2e9fad29e4bbda3832bf8d3300727bc22a70d",3],
  ["0x1680dded5a44a1eadb0a0e51842b4f245df4e629",3],
  ["0x25abe585af591e39a4c025c46dc03789eaf930f5",3],
  ["0x31e6892bf5a0dfb37fdcc02030c2a62f77f5a571",3],
  ["0xac6985d74e69aee5698d51b07520ee6c02cf9f02",3],
  ["0xe63ff459e3cdda922ef4b0c0a9877e579de922cc",3],
  ["0xdd11a8a06c16fa9292c0fb80cc7964c12d467c3f",3],
  ["0xc651d6020031dede84eccaaaf0b7f025938bfce0",3],
  ["0xc3b864a42b794b972ed82f26c9d93b50f28279d6",3],
  ["0xc934544944145535428352f14ba0be654b6cdd19",3],
  ["0x3a986517cec2e2970edfc337dce93fc042719600",3],
  ["0xdecf4b112d4120b6998e5020a6b4819e490f7db6",3],
  ["0x2a637f8db46474ee04159b33c8451320d70938ca",3],
  ["0x37c76bd48f8a7e02d1291ca6bbe5492959ad251b",3],
  ["0x5884866749528f0aa5cd28d90e82e30c568c2ac3",3],
  ["0xbebf53131e47db582f6453a7cfa8827d59f7a6af",3],
  ["0xd9bb2ed5903c08eb14fc99a3f6c67985f6cc7839",3],
  ["0xe85ade296c24b3324bb8f2b0b42dfe74c6f31bc8",3],
  ["0xe06f985f047ce35172c9a584ffafad7c956b6ee1",3],
  ["0x6f178c3c1ac9275bed8f7a70b21e0f669f62c856",3],
  ["0x5bed5d0e719e0e756c523a65fad3a2cf8d0aa37e",3],
  ["0x5689b2a882f0ee55d9fc771b9b36ca8c8571d552",3],
  ["0x33b10a040277d808de1db417ada90c9de79ad0d2",3],
  ["0x6c0d0dbb5191006f03b0add09dac72cd74a71d20",3],
  ["0x2065685879367ff787f19bc0a2bbae2e284dfce4",3],
  ["0x71cc2f12e68fba280aab090332186c465fe0571c",3],
  ["0x37d32679ce9245a3f67d4830cbb3453e85e705d3",3],
  ["0xf8841afb637d2a8220d6caaee0d7af3a13c7e1e7",3],
  ["0x1e931e2f79e70409482d43f59e7ea97c26ccd090",3],
  ["0xdede0e4fda3de6ba791eda156cf2fd900e4936f9",3],
  ["0x5316e5b0668097891553dc45d3a720e42cfb5d1e",3],
  ["0xfe1532eb9a71431b205e5cdc16cb3c009b948d93",3],
  ["0x4890998c5e956bd7f53685d3396671c5d863b5d4",3],
  ["0x1849a094d39bf4ff2305cfd86ef2b38fa032f877",3],
  ["0x0931817f30842f4b67608cb6d2e623d502d6ab14",3],
  ["0x2744fb222220ad088e84a7b61e01840b89ff3a2d",3],
  ["0xc8710c9ca16c5804bb49bf2999645aaf9e97d862",3],
  ["0x8a43219e263659a78b9303d97e0fc8956765ddde",3],
  ["0xd4227764f0186f04c00b7096cd7ede734e091138",3],
  ["0xd9b43e8ac21f40104fe615884504d011cd1242dd",3],
  ["0xfbb80991915d2bdbcb5948478afb2b6c79f16356",3],
  ["0xe696ac159e17d44534d2f3f3ba6d686696b502dc",3],
  ["0x03c24d6910e612ca0c2933b798e26f09223ec13f",3],
  ["0x0355f51f02a51fad9829f594736aef1c40356d1e",3],
  ["0xcc70d6e99ad45626ba80aecfd1f5ea51662e0a5d",3],
  ["0xd0bd462e4655d47445635eda0ce137c2acd976f6",3],
  ["0xb19c1f56f8d2bf268921abc34beaddef5b32e6b6",3],
  ["0x347a0ce4a345f442b1ef9e46bca76407cd3b8815",3],
  ["0x5203569263a3b5b447ba639858a9e9768bb7d147",3],
  ["0x2009a9ba95911fa24f227ee9d447eae97167463b",3],
  ["0x8a54916ff2fd9a8f76ece35f3b84e9ed662d4d57",3],
  ["0x31f2558b405bcd2c83b4addfff1fe05efdb6d8d2",3],
  ["0x1eaa4c4da20a7b944fd2788f0148b7b1c98495f2",3],
  ["0x004fb342f4b36e504f667a4fe6932e0a1e20e529",3],
  ["0x07820f143e56e6191d25304fb4df3e698e15c1fb",3],
  ["0xb491b987a670a7db0210282a4bca439c6ffe4c3a",3],
  ["0x58fe2163a2a538d12a9ba95bba46ae078a4703eb",3],
  ["0x53aabd4f478dea6c25880c7eb38be22ca7110d4d",3],
  ["0xa774c0f0c96d18f68ce5e6927deca26a2e0a19da",3],
  ["0xa9d34bc7d9e571c9b37b061397133af1561e981a",3],
  ["0x7494b0abc6c5ec68afb4d05daa84eb878a7bdf4b",3],
  ["0x74525ec127bb3edfe80b546c5087263a59dbd756",3],
  ["0x852a29a9bed0d505126cd55e4c91163bc4943729",3],
  ["0x18583e33efec371a2e45586545bb5de3272656e7",3],
  ["0x52eaf342132696563bf4bda8171f586ab2679d5f",3],
  ["0xb2f3f1aa397eb140c011e6c37198c5c283f8a6c0",3],
  ["0x90c1185d8322ed3daabf44b09a40519c71e03af5",3],
  ["0x352ed80b5508814b6a808726f928d2737499eef3",3],
  ["0x208427262d6984f978ded97f7d10638bb0ca94a6",3],
  ["0x8315573fd4c4b82caf9f1c8612d2abb1940c93dd",3],
  ["0xbee355672ab8fb7a531d7948555fafbd16f2b31c",3],
  ["0x6fb076a65d44d026ae7bae7d3eb1da982aa86d53",3],
  ["0x4deb3604acac0518703009dc9da2310218a7d9e3",3],
  ["0x2b213ba1f178c08b9fa90b02b0e1d6bdfc9f450a",3],
  ["0x89df776f886e4503516096ed8933c5c4e1ab12c7",3],
  ["0x3baab904a6b3899ecebb349a0f7cbb5a85f1c9bc",3],
  ["0x09faabe6b1638d87d85f9064602b0e0877dc51b3",3],
  ["0xab31fbbb80ab801bd595b82cc92cd646aa4c2f58",3],
  ["0x42f37b0420cc88eb53153c33964944edb70521bc",3],
  ["0x91efd32e53253cc56468981c1d13361ad0d5cb8d",3],
  ["0x70d3009e2d7bc5b0fd5a86adcbef75e81fc034cc",3],
  ["0x5f6ee3220f70cfa2fe974aaa8fea15305ed62e7a",3],
  ["0xa91d28774c9fdc03c4f9830b2f0ef59c660cf472",3],
  ["0x8f19927b88b56417d1dbaf0357386a7a29da30e2",3],
  ["0x4ed3f74a9f9c6ed7e6d766682ef4a8ceb21e851f",3],
  ["0xb2f8e8bf06a3da424f5c06e493af63e2ef10fc4c",3],
  ["0x09c6f773c1cdff63434bebcdb8ece0f37d249121",3],
  ["0x24e060a94de09250f4a293616168f7f20d1f48e5",3],
  ["0x8a6fc5e2829b14afe7d1008711f374bb1745259b",3],
  ["0x9edb55f04d21fef65753483aadb4a74967bc9d3e",3],
  ["0xbe656ad7957d5d73d046721de40c934731998514",3],
  ["0xb828495ef691a168b325565ecfdc8e30288d0a53",3],
  ["0xd97d55fc0bd688f45e4b7601e44998ab3ce16243",3],
  ["0x2b0033851afb9537f3e2f8fbb9e33d06025615bc",3],
  ["0x7382a1f5b992f6f9a59684826aae3706999bb640",3],
  ["0xcd29a73e18395ad3f682e641121d8b28b410229b",3],
  ["0x095d2c4d5c9412a647ebc52afc10ab021ae51aa3",3],
  ["0x30e3ea2a26dce07a959f7d57c826c91a20e92278",3],
  ["0xc843040d0f98a9c13362a3857252ecbcb7420a56",3],
  ["0x209b4b80cded4ed6667705966b45f594504808c4",3],
  ["0x879813a6ecc2ca9de110a86df18a02c007b11cee",3],
  ["0xbdf4dbcade74c532bceb083abf43b7ecb7211f6b",3],
  ["0x1071b12b0eaa61e0571b958d111353a7df6bc6ad",3],
  ["0xf15b2d971b9b320d931b5264df47af3b4db82981",3],
  ["0xcc6ae730ba628a81478e18b1c5e5b593f2c8d74e",3],
  ["0x1dd3270bf0f2b3af93bec29f6da4f8a2f147f16d",3],
  ["0x94588fc488df05e4f79a8cc70296d6902e358563",3],
  ["0x19024dfc106e6111f80225a491b045aa6d8b9941",3],
  ["0x7ea1e2696b0ba0cb03e1fb80fd9b118069f85204",3],
  ["0xdc9648bbd4fc4478feb7a81da25b516e285e5f6a",3],
  ["0xcaf0786ec1b0e39ba4fbe9a759a6e25bd084106d",3],
  ["0xc76611bd74537e0b4f0cdd7c55e551bed99455b0",3],
  ["0x919efb17623f2d728b759d718ed5333ef3b95cb2",3],
  ["0xc5bd35cec17d8a03be9f40293df764453c714dc0",3],
  ["0x5e01fcb1c4b9ad989d4e46461569edb11606ff16",3],
  ["0xe29a198436d2bbd4ec940d7c0d5e6d7d5f962c6b",3],
  ["0x30870fd11dd86bac14eaeab09c3824ff29c5f2a7",3],
  ["0xf432f62fefec9f61618175caf70e9be41485ad0c",3],
  ["0x6f45d685ba194313a61b7f9bd95700d0fdef5653",3],
  ["0x97ba8847eb83138891ad70722bc771b64a96fcbe",3],
  ["0x1caad963e70bf62e63a78d212c83a150992cca6a",3],
  ["0x57be9a1f971c33505fa2a4934c508376cc467c89",3],
  ["0xfb0f5755dc920416b486752423c572451af72aed",3],
  ["0x737cf7fad6c3456297f7bf1aded0206591d4d30d",3],
  ["0xc8729fd36a0a1fc18ef47c5b2b3d5490b94c483e",3],
  ["0x4f6eca606167a45acd1968d9a4291b033855593f",3],
  ["0x6038f2cbaad0ea870b94da8285f1b8bc7a0045bf",3],
  ["0xbcf432f9ca1e21620fd7d5c2bd85cb3ee2233d1b",3],
  ["0x0daa9cd0493bd806567d5e4580eee81688e26bff",3],
  ["0xc76730801ee484a3669fa3f4de5ee26ad04b06e2",3],
  ["0x8a2ad734af176d9922ddf54207f5652e6c52bd7b",3],
  ["0x5da28e35d6684422ad0222dcda6410ff4320ffd3",3],
  ["0x89a8145e5fc02368d7463031e36eae40f660fa8e",3],
  ["0x1f084ea143597e5380d65e6e3e94b68e5801eccf",3],
  ["0xfbb137555bb48b874edd6f4c56b99cd98bc963bd",3],
  ["0x529d10e2dd687841ba30e3ddfb220a9b4ad032cb",3],
  ["0x0f1f87e9d573ffa81c71661b4a7838a89e4693f3",3],
  ["0xc76f7609cbbe2490c0a30bed52dbc88d1b1b8ad7",3],
  ["0x7faf3640ad5eba3e13373cdaac18c387a21b202d",3],
  ["0xa28455c05348b5b82b9e4f7b7efdfa0b405351cc",3],
  ["0x5ee01e23b704c90749bdaeb4abb4787195a7903c",3],
  ["0x65f5f7086d5f5658c0e3e81c53a1b01f1bccb3ec",3],
  ["0x67eaacd04541a3cee4f088d8642c621143235003",3],
  ["0x85888450df39d44f09575ab8fce6c90c71a9493b",3],
  ["0xc6b89a75307767d24e6134141b114f1c647968e7",3],
  ["0x740ee70dc977b00dc9613e2799aedb775432b09b",3],
  ["0x4d65ad90431a60747b6ed17904425932cae08037",3],
  ["0x2e1a2f713526ba740350e4664b6577173d9113fc",3],
  ["0xd3c2087b63559fc8c4b98c99778a086171a9db2b",3],
  ["0xb40bf0773c8c3e22ed70d4dcba0eaa91c96a81ec",3],
  ["0x289c2db3ae761f1e891ecfe506ab0b779fad118c",3],
  ["0x2c89a0a2de8c41ed35ee1eaf32968a5066207fb8",3],
  ["0xafbebc7ebced284e4904f84f344dc1b46eb30b52",3],
  ["0x82fd3d04b25a13c3cc2b172ecb99394aabd05f64",3],
  ["0x0be0d1cca528c3870e4187e2bde9b3861b12f622",3],
  ["0x23f228ad55c03cd70f416d4f0a70cd467ba510c3",3],
  ["0x04e45dc9785cecd1a2ccfb40ad70ad70b3f10d45",3],
  ["0xd80090c6c6e45ee8ae141215ead4cb63baa9882c",3],
  ["0x9469f54132a8449e9e6708e32082faebdaf43c3a",3],
  ["0xd51754d10aeac57b86bf73d516bb2bf4e3ce1d4d",3],
  ["0x0f9831a88abb0ea6479f19af7ab6f53a3c490f72",3],
  ["0x6f8929bf83338de34b74d5decb6520bea6f668f3",3],
  ["0x4900c34274ffc9093b656a7f236f7e6b06d8466f",3],
  ["0x9edb287384bcd1a24d8adb4848df84b3c5f46877",3],
  ["0xfb45cb017c5299fe73e7d6161b722b7f45a934ba",3],
  ["0xbd1eb2c0ab87ca8cac7d5de1dbe1644a5633e692",3],
  ["0xc4dd3b2ec6b4b99e5144841419e24dde44333d02",3],
  ["0x92253b62ee21e12814b5cb4cb2f04c012521cb5b",3],
  ["0xbde5a07e0294ecb79139cfceeb776d47fcdfe7cd",3],
  ["0x53efb1f0ded9ad5e9abdd60a477aa4910dd1fa40",3],
  ["0xfdcd9cc1e2fb96c4d17ebae77f04605e804b8c42",3],
  ["0x236a21e7ce1490022598379bdd3ff6c627a71b23",3],
  ["0x725fe2f2fd851db636bbb0ea912232e0c9549ea3",3],
  ["0xdee73ea9ec14c6febfe321ad79d8227bdb7e4486",3],
  ["0x1c140fcde8d04568842e6f497370c7b416ba2fbb",3],
  ["0x469c66231a70a10201faa3484e46493d27d212dd",3],
  ["0x674bb3aaec189c43cadd8570112560330467b4ef",3],
  ["0xa6aa887f37687e3335fe61acf297782faf951b1a",3],
  ["0x352c546fee434aaf22c1b6666457e0fac438a981",3],
  ["0x487f23166b47b09f43ea4442c90a3b89a1dc431d",3],
  ["0x6dd61202519346d71cc581ac685943b6effcec1a",3],
  ["0x63c3340d70546a3773425a3bceffeedf1b841a4b",3],
  ["0xb18f86c0d51be44226095e344ef705c693a63b96",3],
]